<template>
  <div>
    <div id="printcontent" v-html="datasource.print_html"></div>
  </div>
</template>
<script>
import utils from "@/service/utils";
import api from "@/service/api";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
     
  },

  data() {
    return {
      datasource: {
        qp: { uniq: "", device_id: "" },
        print_html:null
      },
    };
  },
  created() {

 
    this.datasource.qp.uniq      = this.$route.query.uniq;
    this.datasource.qp.device_id = this.$route.query.device_id;

    let postData = { sf_uniq:this.datasource.qp.uniq,device_id:this.datasource.qp.device_id };
    api.action('printservicedevicereceipt',postData).then(q=>
    {
      this.datasource.print_html = q.data;
      setTimeout(() => {
        //window.print();
        this.$htmlToPaper('printcontent', {
           specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
          ],
          autoClose: true, 
         });
      }, 50);
    })

/*
    try {
      this.datasource.qp.uniq = this.$route.query.uniq;
      this.datasource.qp.device_id = this.$route.query.device_id;
      this.sf.sform.sf_uniq = this.datasource.qp.uniq;
      this.getServiceDetails();
    } catch (e) {
      utils.logError(e);
    }
    */
  },
  methods: {
    ...mapActions(["getServiceDetails"]),

    getDeviceTakeDeliveryAddress(device_item) {
      try {
        let result = "";
        if (this.sf == null || this.sf.sform == null) return result;
        let takeAddress = device_item.delivery_items.find(
          (q) => q.type == "take"
        );
        if (takeAddress) {
          result =
            takeAddress.delivery_address +
            "<br />" +
            takeAddress.district_text +
            "/" +
            takeAddress.city_text;
        }
        return result;
      } catch (e) {
        utils.logError(e);
      }
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    devices() {
      let result = this.sf.devices;
      if (this.datasource.qp.device_id != null) {
        result = result.filter(
          (q) => q.device_id == this.datasource.qp.device_id
        );
      }

      return result;
    },
  },
  watch: {
    "sf.sform": function (e, v) {
      setTimeout(() => {
        window.print();
      }, 100);
    },
  },
};
</script>
<style lang="scss">
 #printcontent
 {
    margin:0px;
    padding:0px;
 }

 @media print 
 {
  html,body
  {
    padding: 0px;
    margin:0px;
  }
   #printcontent
   {
    margin:0px;
    padding:0px;
   }
   
   .navbar
   {
    display: none !important;
   }
   .app-content
   {
    padding:0px !important;
    margin:0px !important;
    height: auto !important;
    min-height: auto !important;
   }

   footer
   {
      display: none !important;
   }


 }
</style> 